<template>
  <TheCalendar />
</template>

<script>
import TheCalendar from './components/TheCalendar.vue'

export default {
  name: 'App',
  components: {
    TheCalendar
  }
}
</script>